.recipe-filter .top-line {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 1em;
    margin-bottom: 1em;
  }
  
.recipe-filter .full-width {
    width: 100%;
}

.food-filter-categories {
    margin-top: 2em;
    padding-top: 1em;
    border-top: 1px solid silver;
    text-align: center;
}