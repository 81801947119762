.recipe-view {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
}

.recipe-view .navds-tabs__tablist {
  margin-top: 1rem;
}

.recipe-view-top-navigation {
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 90%;
  height: 2em;
  background: #f5f5f578;
  padding-left: 1em;
  z-index: 100;
}

.recipe-view-top-navigation div:nth-child(2) {
  flex-grow: 2;
}

.recipe-view-top-navigation .back-navigation img {
  height: 1em;
  vertical-align: middle;
}

.recipe-view-top-navigation .sharingbut {
}

.recipe-view-top-navigation .favorite-icon {
  margin-bottom: 10px;
  margin-top: 10px;
  margin-right: 1em;
}

.recipe-view-top-navigation .sharingicon svg {
  vertical-align: middle;
}

.recipe-view-missing-home-ingredients {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 3em;
  font-family: var(--font-title);
}

.recipe-view-title {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 2em;
  font-family: var(--font-title);
}

.recipe-title {
  font-weight: 700;
  font-size: x-large;
  color: var(--color-main-grey-2);
  /*font-family: var(--font-logo);*/
  font-family: var(--font-title);
}

.recipe-view .top-line {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 1em;
  margin-bottom: 1em;
}

.recipe-ingredients ul {
  list-style-type: none;
}

.ingredient-section-title:first-of-type {
  margin-top: 0;
}

.ingredient-section-title {
  font-weight: bold;
  margin-top: 0.7em;
}

.recipe-ingredients-shopping-list {
  width: 100%;
  text-align: right;
  margin-right: 1rem;
}

.recipe-author {
  margin-right: 1em;
  /*margin-bottom: 0.5em;*/
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
}

.recipe-author a {
  text-decoration: none;
  display: flex;
  flex-direction: row;
  border-bottom: 0px dashed silver;
  align-items: center;
  text-align: right;
  color: var(--color-heavy-green)
}

.recipe-author a:visited {
  color: var(--color-heavy-green)
}

.recipe-author a div {
  margin-left: 1em;
}

.recipe-author img {
  width: 3em;
}

.recipe-images {
  width: 100%;
  text-align: center;
}

.recipe-images img {
  width: 100%;
}

.recipe-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px dashed silver;
  width: 100%;
}

.recipe-details-row .recipe-servings {
  margin-right: 1em;
}

.recipe-details-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  margin-top: 0.5rem;
}

.recipe-difficultyLevel {
    margin-left: 20px;
}

.recipe-addFavorite {
    margin-left: 30px;
}

.recipe-content {

}

.recipe-content .recipe-intro-text {
  white-space: pre-line;
  margin-top: 1rem;
}

.recipe-content .recipe-footer-text {
  white-space: pre-line;
  margin-top: 1rem;
}

.recipe-ingredients {
}

.recipe-ingredients ul {
  padding: 1em;
}

.recipe-ingredients-title {
  font-weight: 500;
  font-size: x-large;
  margin-top: 1em;
  margin-bottom: 0.5em;
  text-align: center;
  display: flex;
  font-family: var(--font-title);
}

.recipe-ingredients-title span:first-child {
  flex-grow: 2;
}

.recipe-ingredients-title img {
  height: 48px;
}

.recipe-cooking-steps {
  margin-bottom: 1em;

}

.recipe-cooking-steps ul {
  padding: 1em;
  list-style-type: decimal;
}

.recipe-cooking-steps ul li {
  margin-bottom: 0.5em;
}

.recipe-cooking-steps-title {
  font-weight: 500;
  font-size: x-large;
  margin-top: 1em;
  margin-bottom: 0.5em;
  text-align: center;
  font-family: var(--font-title);
}

.recipe-speak-icon {
  margin-right: 0.5rem;
  border: 1px solid silver;
  padding: 0.5rem;
}
