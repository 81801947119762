.favorite-recipes-view {
    width: 100%;
}

.favorite-recipes-title {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
}

.favorite-recipes-title .back-navigation {
    margin-right: 6px;
    position: relative;
    left: -6px;
}

.favorite-recipes-view .recipe-favorite-item {
    width: 100%;
}

.favorite-recipes-view .remove-favorite-button {
    border: 1px solid green;
    border-radius: 10px;
    margin-top: 0.5px;
    margin-bottom: 10px;
    background-color: transparent;
}


