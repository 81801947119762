.day-number {
    font-size: x-large;
    text-align: center;
    font-weight: 600;
    margin-bottom: 0.5em;
    margin-top: 1em;
}

.recipe-day-list-view {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
}

.recipe-day-list-item {
    width: 100%;
}

.recipe-create-new-list {
    width: 100%;
    display: flex;
    justify-content: center;
    color: green;

    margin-top: 2rem;
    margin-bttom: 2rem;
}

.recipe-create-new-list {
    height: auto;
    margin-top: 30px;
    border: 1px solid #1f856f;
    border-radius: 20px;
    display: flex;
    align-items: center;
    padding: 10px 0 10px 5px;
}
