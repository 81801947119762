.recipe-alternatives-view {
    width: 100%;
}

.recipe-alternatives-view-title {
    display: flex;
    flex-direction: row; 
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
}

.recipe-alternatives-view-title .back-navigation {
    margin-right: 6px;
    position: relative;
    left: -6px;
}

.recipe-alternatives-view .recipe-title {
    font-weight: 700;
    font-size: x-large;
}