.shopping-item {
  display: flex;
  width: 100%;
  border-bottom: 1px solid #dadce0;
  padding: 10px;  
  margin-bottom: 10px;
}

.shopping-item.checked{
  text-decoration: line-through;
}

.shopping-item.checked::after{
  position: absolute;
  right: 30px;
  font-family: bootstrap-icons !important;
  content: "\f633";
}

.shopping-item .item-details input {
  background: transparent;
  border-bottom: 1px dashed grey;
}

