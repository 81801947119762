.home-food-ingredient {
    border: 1px solid silver;
    background-color: #30bc9e4f;
    border-radius: 6px;
    width: 85px;
    height: 85px;
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 4px;
}

.home-food-ingredient .icon {
    width: 50px;
    height: 50px;
}

.home-food-ingredient .icon img {
    width: 50px;
    height: 50px;
}

.home-food-ingredient .ingredient-name {
    margin-top: 0px;
    font-size: 12px;
    text-align: center;
}

.home-food-ingredient .ingredient-quantity {
    font-size: 10px;
    text-align: center;
}

