.selectable-food-item {
    border: 1px solid silver;
    background-color: #307abc1f;
    border-radius: 6px;
    width: 80px;
    height: 90px;
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 4px;
}

.selectable-food-item .icon {
    width: 50px;
    height: 50px;
}

.selectable-food-item .icon img {
    width: 50px;
    height: 50px;
}

.selectable-food-item .food-name {
    margin-top: 0px;
    font-size: 12px;
    text-align: center;
}

