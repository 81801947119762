.sharingbut {
  background-color: transparent;
  border: none;
  text-align: center;
  cursor: pointer;
  padding: 0;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 0.5rem;
}
.sharingbut .sharingicon {
  color: var(--color-heavy-green);
  display: inline;
  height: 1rem;
}
.sharingbut span {
  padding-left: 10px;
}

.sharing-modal {
  position: fixed;
  z-index: 1;
  left: 40%;
  top: 40%;
  width: auto;
  height: auto;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.linkLine {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sharing-modal h2 {
  font-size: 24px;
  margin-top: 0;
}

.sharing-modal p {
  font-size: 16px;
  margin-bottom: 10px;
}

.sharing-modal input[type='text'] {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
}

.sharing-apps {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.sharing-app {
  padding: 10px;
  border-radius: 5px;
  margin-right: 10px;
  margin-bottom: 10px;
  text-decoration: none;
  cursor: pointer;
  color: #28af93;
}

.sharing-app:hover {
  background-color: #939393;
}
