.language {
  height: auto;
  margin-top: 30px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding-left: 1rem;
  padding-bottom: 1rem;
}

.language .form-check {
  padding-top: 1rem;
}

.editla {
  display: flex;
  justify-content: space-between;
}

.editla button {
  border: 0;
  background-color: #f5f5f5;
  color: #1f856f;
  font-weight: 700;
  margin-top: 10x;
}

.modal {
  position: fixed;
  top: 30%;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  border: none;
}

.modal-header {
  border-bottom: none;
  color: #24937b;
}

.modal-footer {
  border-top: none;
}

.modal-footer button {
  background-color: white;
  color: #24937b;
  border: none;
  font-weight: 700;
}

.form-check-input:checked {
  background-color: #24937b;
}

.language span {
  margin-left: 10px;
  margin-top: 10px;
}

.language p {
  margin-left: 10px;
  margin-top: 15px;
  font-weight: 800;
}
