.food-at-home {
    width: 100%;
    height: 100%;
}

.have-food {
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.enter-food {
    border-top: 1px solid #30bc9eFF;
    border-left: 1px solid #30bc9eFF;
    border-right: 1px solid #30bc9eFF;
    border-radius: 10px 10px 0px 0px;

    margin-top: 10px;
    width: 100%;
    padding: 10px ;
    background-color: #30bc9e11;
    display: flex;
    flex-direction: column;
}

.food-at-home .button-language {
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    height: auto;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}