.recipe-summary-view {
    margin-bottom: 1em;
    padding: 0em;
    border: 0px solid green;

    width: 100%;
    border-radius: 1em 1em 0 1em;
    position: relative;
    background-color: transparent;
}

.recipe-summary-view-image {
    border-bottom: 0px solid black;
    max-height: 15em;
    text-align: center;
}

.recipe-summary-view-image img {
    max-height: 15em;
    border-radius: 1em 1em 0 0em;
    max-width: 100%;
    width: 100%;
    border-bottom: 0px solid black;
}

.recipe-summary-view-author {
    position: absolute;
    right: 0.1em;
    top: 0.5em;
    width: 3em;
}

.recipe-summary-view-author img {
    max-width: 3em;
}

.recipe-summary-view-author {

}

.recipe-summary-view-description {
    width: 100%;
    overflow: hidden;
    padding-left: 0em;
    border-radius: 0em 0em 1em 1em;
    text-align: center;
    display: flex;
    align-items: center;
    max-height: 5em;
    min-height: 5em;
    border: 1px solid var(--color-main-green);
}

.recipe-summary-view-description a {
    text-decoration: none;
    color: var(--color-main-grey-2);
}

.recipe-summary-view-title {
    width: 100%;
    padding-right: 10px;
    padding-left: 10px;
    font-weight: 600;
}

.recipe-summary-favorite {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 0.4rem;
}
