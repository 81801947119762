.enter-food {
    border-top: 1px solid #30bc9eFF;
    border-left: 1px solid #30bc9eFF;
    border-right: 1px solid #30bc9eFF;
    border-bottom: 1px solid #30bc9eFF;
    border-radius: 10px 10px 0px 0px;

    margin-top: 10px;
    width: 100%;
    padding: 10px ;
    background-color: #30bc9e11;
    display: flex;
    flex-direction: column;
}

.enter-food label {
    margin-bottom: 0.5rem;
}

.recipe-filter .top-line {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 1em;
    margin-bottom: 1em;
  }
  
.recipe-filter .full-width {
    width: 100%;
}

.recipe-filter .food-filter-categories .navds-chips {
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.recipe-filter .food-filter-categories .navds-chips button {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: large;
}

.recipe-filter .recipe-tags-section .form-check {
    font-size: large;
}

.recipe-filter .RecipeTags .recipe-tags-section .recipe-tags-section-options label {
    margin-right: 1.75rem;
    margin-left: -0.15rem;
    margin-bottom: 0.5rem;
}

.food-filter-categories {
    display: flex;
    justify-content: center;
    margin-top: 2em;
    margin-bottom: 2em;
    padding-top: 3em;
    border-top: 1px solid silver;
    text-align: center;
}

.enter-food .food-at-home {
    display: flex;
    flex-flow: row;
}

.enter-food .selectable-food-list {
    max-height: 18.5rem;
    overflow: hidden;
}

.find-recipes {
    width: 100%;
    display: flex;
    justify-content: center;
}

.find-recipes .button-language {
    width: 100%;
    margin-top: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
}