.profile {
  text-align: left;
  margin: 0 auto;
  width: 100%;
  max-width: 800px; /* update max-width to 800px */
  padding: 10px;
}
.profile h3 {
  text-align: center;
  margin-top: 20px;
}

.profile .suggested-recipes {
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 100%;
}

.profile .suggested-recipes button {
  width: 100%;
}

.profile-section {
  margin-top: 2rem;
  margin-bttom: 2rem;
}

@media only screen and (max-width: 600px) {
  /* adjust max-width for smaller screens */
  .profile {
    max-width: 95%;
  }
}

.preference {
  height: auto;
  margin-top: 30px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 0.5rem;
}

.preference p {
  padding-top: 10px;
  margin-left: 10px;
}
.foodpre {
  padding-left: 10px;
  padding-bottom: 10px;
  padding-top: 10px;
}

.donot {
  height: auto;
  margin-top: 30px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  padding: 10px 0 10px 5px;
}

.donot span {
  padding-left: 5px;
}

.donotcore {
  margin: 15px;
}

.sharing {
  height: auto;
  margin-top: 30px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
}
.profile .donotcore input[type='checkbox'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #1f856f;
  border-radius: 4px;
  background-color: #fff;
  margin-right: 10px;
  vertical-align: middle;
  position: relative;
}

.profile .donotcore input[type='checkbox']:checked {
  background-color: #1f856f;
}

.profile .donotcore input[type='checkbox']:checked::before {
  content: '\2713';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  color: #fff;
  font-size: 14px;
  position: absolute;
  top: 1px;
  left: 0;
  width: 14px;
  height: 14px;
  text-align: center;
  line-height: 14px;
  border-radius: 2px;
}
