.home-content {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}

.home-content div {
    flex-grow: 1;
    max-height: 60px;
}