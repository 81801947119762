.food-filter-selections {
    margin-top: 0.5em;
    display: flex;
    flex-wrap: wrap;
}

.food-filter-selected {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid silver;
    margin: 0.1em;
    padding: 0.2em;
    border-radius: 0.5em;
}

.food-filter-selected-title {
    padding: 0.2em 0.4em 0.3em 0.2em;
    border-right: 1px solid silver;
    margin-right: 0.1em
}

.food-filter-selected-close {
    display: flex;
}