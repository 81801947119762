.home-food-input {
    margin-top: 10px;
    margin-bottom: 20px;
}

.home-food-input input {
    padding-left: 10px;
    border-radius: 10px;
    border: 0px ;
    font-size: large;
    line-height: 2;
    width: 100%;
}