.shopping-list {
  width: 100%;
}

.shopping-list p {
  margin-top: -10px;
  font-size: 1rem;
  color: #5f6367;
}

.shopping-list .no-item {
  color: #bcc0c9;
  text-align: center;
}

.add-shopping-list-item {
  display: flex;
  align-items: center;
  margin: 20px 0;
  width: 100%;
  border-bottom: 1px solid black;
  padding-bottom: 0px;
}

.add-shopping-list-item input[type='text']::placeholder {
  color: #5f6367;
  font-size: small;
}

.add-shopping-list-item input[type='text'] {
  border: 0;
  outline: none;
  background: transparent;
  flex-grow: 1;
  width: 90%;
}

.shopping-list-items {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
}
